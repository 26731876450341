'use strict';

(function() {

  /**
   * The Util service is for thin, globally reusable, utility functions
   */
  function UtilService($window) {
    var Util = {
      /**
       * Return a callback or noop function
       *
       * @param  {Function|*} cb - a 'potential' function
       * @return {Function}
       */
      safeCb(cb) {
        return (angular.isFunction(cb)) ? cb : angular.noop;
      },

      /**
       * Parse a given url with the use of an anchor element
       *
       * @param  {String} url - the url to parse
       * @return {Object}     - the parsed url, anchor element
       */
      urlParse(url) {
        var a = document.createElement('a');
        a.href = url;

        // Special treatment for IE, see http://stackoverflow.com/a/13405933 for details
        if (a.host === '') {
          a.href = a.href;
        }

        return a;
      },

      /**
       * Test whether or not a given url is same origin
       *
       * @param  {String}           url       - url to test
       * @param  {String|String[]}  [origins] - additional origins to test against
       * @return {Boolean}                    - true if url is same origin
       */
      isSameOrigin(url, origins) {
        url = Util.urlParse(url);
        origins = (origins && [].concat(origins)) || [];
        origins = origins.map(Util.urlParse);
        origins.push($window.location);
        origins = origins.filter(function(o) {
          return url.hostname === o.hostname &&
            url.port === o.port &&
            url.protocol === o.protocol;
        });
        return (origins.length >= 1);
      },

      inputDecorator(form, inputField, submitted) {
        return {
          "has-success": (form[inputField].$valid && form[inputField].$touched) || ( form[inputField].$valid && submitted),
          "has-error": (form[inputField].$invalid && form[inputField].$touched) || ( form[inputField].$invalid && submitted )
        }
      },

      getFindingClass(finding) {
        return {
          'resolved': finding.status == 'RESOLVED', 'critical': finding.category == 5,
          'major': finding.category == 4, 'serious': finding.category == 3,
          'medium': finding.category == 2, 'low': finding.category == 1,
          'no-risk': finding.category == 0}
      },

      getFindingCategoryText(category) {
        if(category == 0) {
          return 'No Risk';
       }
        if(category == 1) {
           return 'Low';
        }
        if(category == 2) {
           return 'Medium';
        }
        if(category == 3) {
           return 'Serious';
        }
        if(category == 4) {
           return 'Major';
        }
        if(category == 5) {
           return 'Critical';
        }
        return 'N/A';
      },

      getTotalCapacity(turbines) {
        let capacity = 0;
        if (turbines && turbines.length) {
          turbines.forEach((a) => {
            capacity += (a.capacity ||0)
          });
          return capacity.toFixed(2);
        }
        return 0;
      },

      getDefectRecommendation(category) {
        if(category == 1 || category == 0) {
           return 'No action required';
        }
        if(category == 2) {
           return 'Monitor during inspection schedule/repair if repairing other findings';
        }
        if(category == 3) {
           return 'Reinspect/Repair within 6 months';
        }
        if(category == 4) {
           return 'Reinspect/Repair within 3 months';
        }
        if(category == 5) {
           return 'Critical - STOP turbine investigate/repair/replace immediately';
        }
        return '';
      },

      getCavityInspectionTypeId: function(inspectionType) {
        if(!inspectionType) {
          return '';
        }
        const inspectionTypeStr = inspectionType.toLowerCase();
        if(inspectionTypeStr === 'le cavity') {
          return 'A';
        } else if(inspectionTypeStr === 'between main shear webs') {
          return 'B';
        } else if(inspectionTypeStr === 'btwn main shear webs and 3rd web' || inspectionTypeStr === 'between main shear webs and 3rd web') {
          return 'C';
        } else if(inspectionTypeStr === 'te cavity') {
          return 'D';
        }
      },

      getAddressForReport(location) {
        let address = '';
        if(!location) {
          return 'N/A';
        }
        // Format city, region/state, postal code, country
        if (location.addressLine3 && location.addressLine3.length) {
          address += location.addressLine3;
        }
        if (location.addressLine4 && location.addressLine4.length) {
          address += ', ' + location.addressLine4;
        }
        if (location.addressLine5 && location.addressLine5.length) {
          address += ', ' + location.addressLine5;
        }
        if(location.country) {
          address += ', ' + location.country;
        }
        return address;
      },

      getAddress(location) {
        let address = '';
        if(!location) {
          return 'N/A';
        }
        if (location.addressLine1 && location.addressLine1.length) {
          address += location.addressLine1;
        }
        if (location.addressLine2 && location.addressLine2.length) {
          address += ', ' + location.addressLine2;
        }
        if (location.addressLine3 && location.addressLine3.length) {
          address += ', ' + location.addressLine3;
        }
        if (location.addressLine4 && location.addressLine4.length) {
          address += ', ' + location.addressLine4;
        }
        if (location.addressLine5 && location.addressLine5.length) {
          address += ', ' + location.addressLine5;
        }
        // console.log('in get adr: ', location.country);
        if(location.country) {
          address += ', ' + location.country;
        }
        return address;
      },

      getLatLng(location) {
        if(location.position && location.position.length == 2) {
          return `${location.position[1]},${location.position[0]}`;
        }
        return null;
      },

      toShowError(form, inputField, submitted) {
        return form[inputField].$error.required && (form.$invalid && form[inputField].$touched) || ( form[inputField].$invalid && submitted );
      },

      isArray(value) {
        return !!(value instanceof Array);
      }
    };

    return Util;
  }

  angular.module('windmanagerApp.util')
    .factory('Util', UtilService);

})();
